<!--header的下拉选择-->
<template>
  <div class="product-display product-hidden" @mouseleave="productNotShow" @mouseenter="productShow">
    <div class="product-big-box">
      <div class="product-box">
        <img src="../assets/img/vectorField.png">
        <router-link to="/phase/desc">Caputo分数阶系统相图绘制软件</router-link>
      </div>
      <div class="product-box">
        <img src="../assets/img/homepage-mzsProcessor.jpg">
        <a href="">质谱数据处理软件（开发中）</a>
      </div>
    </div>



     <!-- <el-row>
    <el-col
      v-for="(o, index) in 2"
      :key="o"
      :span="8"
      :offset="index > 0 ? 2 : 0"
    >
      <el-card :body-style="{ padding: '0px' }">
        <img
          src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
          class="image"
        />
        <div style="padding: 14px">
          <span>Yummy hamburger</span>
          <div class="bottom">
            <el-button text class="button">Operating</el-button>
          </div>
        </div>
      </el-card>
    </el-col>
  </el-row> -->
  </div>
</template>


<script>
export default {
  name: "header-product-select",
  methods: {
    productShow() {
      document.querySelector('.product-display').className = 'product-display product-active';
    },
    productNotShow() {
      document.querySelector('.product-display').className = 'product-display product-hidden';
    }
  }
}
</script>

<style>
.button {
  padding: 0;
  min-height: auto;
}
.image {
  width: 100%;
  display: block;
}
.product-hidden {
  position: fixed;
  height: 40vh;
  width: 100%;
  top: -40vh;
  background-color: rgba(255, 255, 255, 1);
  transition: top 0.5s ease;
}

.product-active {
  z-index: 2;
  position: fixed;
  top: 0vh;
  height: 40vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  transition: top 0.5s ease;
}

.product-big-box {
  height: 100%;
  padding: 5vh 10vh;
  display: flex;
}

.product-box {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
}


.product-box img {
  max-width: 100%;
  max-height: 30vh;
  border-radius: 10px; /* 添加圆角边框 */
  margin-bottom: 10px;
}

.product-box a {
  font-size: 18px;
  text-decoration: none;
  color: black;
}

.product-box a:hover {
  text-decoration: underline;
  color: crimson;
}

</style>