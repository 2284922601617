<template>
  <header v-if="isMobile">
    <!-- <el-button round type="info" pri @click="click_menu">Menu</el-button> -->
    <!-- <el-button type="info" :icon="ArrowLeft" @click="click_menu"></el-button> -->

    <div
      style="
        background-color: rgba(255, 255, 255, 1);
        width: 100vw;
        padding: 14px;
      "
    >
      <el-icon
        @click="click_menu"
        color="black"
        style="font-weight: 800; font-size: 30px"
      >
        <Operation />
      </el-icon>
    </div>

    <el-drawer
      v-model="isCollapse"
      direction="ltr"
      :before-close="handleClose"
      size="20%"
    >
      <el-menu
        default-active="2"
        class="tac"
        :collapse="isCollapse"
        @open="handleOpen"
        @close="handleClose"
        background-color="rgba(250,250,255,0.3)"
      >
        <el-menu-item index="5">
          <a href="/" style="text-decoration: none">首页</a>
        </el-menu-item>
        <!-- <el-sub-menu index="1">
          <template #title> 研究</template>
          <el-menu-item index="1-1"
          ><a href="/research" style="text-decoration: none">
            动力系统
          </a></el-menu-item
          >
          <el-menu-item index="1-2"
          ><a href="/research" style="text-decoration: none">
            数值建�?
          </a></el-menu-item
          >
          <el-menu-item index="1-3"
          ><a href="/research" style="text-decoration: none">
            �?动控�?
          </a></el-menu-item
          >
          <el-menu-item index="1-4"
          ><a href="/research" style="text-decoration: none">
            神经网络
          </a></el-menu-item
          >
          <el-menu-item index="1-4"
          ><a href="/research" style="text-decoration: none">
            无人飞机
          </a></el-menu-item
          >
          <el-menu-item index="1-4"
          ><a href="/research" style="text-decoration: none">
            医�?�应�?
          </a></el-menu-item
          >
          <el-menu-item index="1-4"
          ><a href="/research" style="text-decoration: none">
            工业应用
          </a></el-menu-item
          >
        </el-sub-menu> -->
        <el-sub-menu index="2">
          <template #title> 产品</template>
          <el-menu-item index="2-1">
            <a href="/phase/desc " 
              >Caputo分数阶相图</a
            >
          </el-menu-item>
          <el-menu-item index="2-2">
            <a href="/corelessUAVDes1">
                空心杯无人机
              </a>
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item index="3">
          <a href="/contact" style="text-decoration: none">
            <el-text class="mx-1" size="large">联系</el-text>
          </a>
        </el-menu-item>
        <el-sub-menu index="4">
          <template #title>
            <a v-if="this.userEmail === null">用户</a>
            <a v-else :text="this.userEmail"></a>
          </template>
          <el-menu-item v-if="this.userEmail == null" index="4-1">
            <a href="/login" style="text-decoration: none">
              <el-text class="mx-1" size="large">用户登录/注册</el-text>
            </a>
          </el-menu-item>
          <el-menu-item index="4-2" v-else :text="this.userEmail">
            <a href="/" @click="loginOut" style="text-decoration: none">
              <el-text class="mx-1" size="large"> 退出登录</el-text>
            </a>
          </el-menu-item>
        </el-sub-menu>
      </el-menu>
    </el-drawer>
  </header>

  <header v-else :class="{ scrolled: isScrolled }" id="header">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-s"
      mode="horizontal"
      :ellipsis="false"
    >
      <el-menu-item class="el-menu-item-s">
        <router-link to="/">
          <h1 style="width: 150px" href="" class="logo">
            <img
              style="margin-left: 20px"
              src="../assets/img/logo_gosla_red.svg"
            />
          </h1>
        </router-link>
      </el-menu-item>
      <div class="flex-grow" />
      <!-- <el-sub-menu index="6">
        <template #title>
          <div class="menu-text">无人�?</div>
        </template>
        <el-menu-item index="6-1">
          <a href="/UAVHistory" style="text-decoration: none">
            无人机发展历�?
          </a>
        </el-menu-item>
      </el-sub-menu> -->
      <!-- <el-sub-menu index="2">
        <template #title>
          <div class="menu-text">科�?�研�?</div>
        </template>

        <el-menu-item index="2-1">
          <a href="/research" style="text-decoration: none">
            动力系统
          </a></el-menu-item
        >
        <el-menu-item index="2-2"
        ><a href="/research" style="text-decoration: none">
          数值建�?
        </a></el-menu-item
        >
        <el-menu-item index="2-3"
        ><a href="/research" style="text-decoration: none">
          �?动控�?
        </a></el-menu-item
        >
        <el-menu-item index="2-4"
        ><a href="/research" style="text-decoration: none">
          神经网络
        </a></el-menu-item
        >
        <el-menu-item index="2-5"
        ><a href="/research" style="text-decoration: none">
          无人飞机
        </a></el-menu-item
        >
        <el-menu-item index="2-6"
        ><a href="/research" style="text-decoration: none">
          医�?�应�?
        </a></el-menu-item
        >
        <el-menu-item index="2-7"
        ><a href="/research" style="text-decoration: none">
          工业应用
        </a></el-menu-item
        >
      </el-sub-menu> -->

      <el-dropdown placement="bottom">
        <el-button class="el-button-text centered-button"> 产品 </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              ><a href="/phase/desc" class="dropdown-link">
                相图绘制软件
              </a></el-dropdown-item
            >
            <el-dropdown-item
              ><a href="/corelessUAVDes1" class="dropdown-link">
                空心杯无人机
              </a></el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-button class="el-button-text centered-button">
        <a href="/contact" style="text-decoration: none">联系</a>
      </el-button>
      <el-dropdown placement="bottom" v-if="this.userEmail === null">
        <el-button class="el-button-text centered-button"> 用户 </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              ><a href="/login" class="dropdown-link">
                登录
              </a></el-dropdown-item
            >
            <el-dropdown-item
              ><a href="/register" class="dropdown-link">
                注册
              </a></el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dropdown placement="bottom" v-else :text="this.userEmail">
        <el-button class="el-button-text centered-button">{{
          this.userEmail
        }}</el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              ><a href="/" class="dropdown-link" @click="loginOut">
                退出登录
              </a></el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <!-- 增加一段空白区域 -->
      <div class="spacer"></div>
      <!-- <el-menu-item index="3">
        <a href="#" @mouseenter="productShow" style="text-decoration: none">
          <div class="menu-text">�?司产�?</div>
        </a>
      </el-menu-item> -->
      <!-- <el-menu-item index="4">
        <a href="/contact" style="text-decoration: none">
          <div class="menu-text">联系我们</div>
        </a>
      </el-menu-item> -->
      <!-- <el-sub-menu index="5">
        <template #title>
          <el-button type="danger" round>
            <div class="menu-text">
              <a v-if="this.userEmail === null">用户</a>
              <a v-else :text="this.userEmail"></a>
            </div>
          </el-button>
        </template>
        <el-menu-item v-if="this.userEmail === null" index="5-1">
          <a href="/login" style="text-decoration: none">
            <el-text class="mx-1" size="large">用户登录/注册</el-text>
          </a>
        </el-menu-item>
        <el-menu-item index="5-2" v-else :text="this.userEmail">
          <a href="/" @click="loginOut" style="text-decoration: none">
            <el-text class="mx-1" size="large"> 退出登�?</el-text>
          </a>
        </el-menu-item>
      </el-sub-menu> -->
    </el-menu>
  </header>
</template>

<script>
import HeaderSelect from "@/components/productSelect.vue";
import GoslaHeaderSelect from "@/components/productSelect.vue";
import ProductSelect from "@/components/productSelect.vue";
import HeaderProductSelect from "@/components/productSelect.vue";
import { Hide, View, ArrowLeft } from "@element-plus/icons-vue";
import { ref } from "vue";

const openMenu = () => {
  document.querySelector(".backdrop").className = "backdrop active";
  document.querySelector("aside").className = "active";
};
const closeMenu = () => {
  document.querySelector(".backdrop").className = "backdrop";
  document.querySelector("aside").className = "";
};
/*添加product的鼠标监�?*/

export default {
  name: "goslaHeader",
  components: {
    HeaderProductSelect,
    ProductSelect,
    GoslaHeaderSelect,
    HeaderSelect,
    Hide,
    View,
    ArrowLeft,
  },
  data() {
    return {
      userEmail: "",
      isMobile: ref(window.innerWidth < 768),
      isCollapse: ref(false),
      isScrolled: false,
    };
  },
  beforeMount() {
    var email = null;
    try {
      email = sessionStorage.getItem("userEmail");
    } catch (e) {
      sessionStorage.setItem("userEmail", null);
    }
    this.userEmail = email;
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    menuClick(event) {
      event.preventDefault();
      openMenu();
    },
    menuClose(event) {
      event.preventDefault();
      closeMenu();
    },
    productShow() {
      document.querySelector(".product-display").className =
        "product-display product-active";
    },
    loginOut() {
      sessionStorage.removeItem("userEmail");
    },
    click_menu() {
      this.isCollapse = true;
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 50;
    },
  },
};
</script>

<style scoped>
.el-button-text {
  font-family: inherit;
  font-size: 1.25rem;
  text-decoration: none;
}

.centered-button {
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  height: 100%; /* �?保按�?的高度占满父元素 */
  background-color: transparent !important; /* 无背�?颜色 */
  box-shadow: none !important; /* 移除阴影 */
  border: none !important; /* 移除边�?? */
  outline: none;
}

.centered-button:hover,
.centered-button:focus,
.centered-button:active {
  background-color: transparent !important; /* 在悬停、聚焦和点击时保持背�?透明 */
  border: none !important; /* 在所有状态下都保持无边�?? */
  box-shadow: none !important; /* 在所有状态下都保持无阴影 */
  outline: none;
}
/* 非悬停状态下 */
.centered-button:not(:hover) {
  color: black;
}

.dropdown-link {
  font-size: 1.1rem; /* 调整字体大小 */
  color: black; /* 设置字体颜色为黑�? */
  text-decoration: none; /* 移除下划�? */
  padding: 10px 20px;
}
.dropdown-link:hover {
  color: #409eff;
}

.el-menu-s {
  background: none;
}

.el-menu-item-s:hover {
  opacity: 0.5;
}

.menu-botton {
  /* font-family: "Fangzheng Lantinghei", sans-serif, "Source Han Serif", serif; */
  font-size: 18px;
  border-radius: 10px; /* 圆�?�半径，根据需要调�? */
  background-color: blue; /* 蓝色背景，根�?需要调�? */
  color: white; /* 文字颜色，根�?需要调�? */
  display: inline-block; /* 使元素成为内联块级元素，以使设置宽度、高度和内边距更为方�? */
}

.el-menu--horizontal.el-menu {
  border-bottom: 0;
}

.logo {
  height: 64px;
  width: 64px;
  color: inherit;
}

.flex-grow {
  flex-grow: 1;
}

header {
  /* display: grid; */
  /*Grid 布局�?�?剩余空间(leftover space)的一部分(fraction)�? 一�?来�??1fr 的意思是�?100%的剩余空间�?*/
  grid-template-columns: auto 1fr auto auto;
  padding: 0px 0px;
  /*position:fixed�?对于浏�?�器窗口定位的，要实现相当于父元素定�?,相当于窗口定位的，实现效果上�?相�?�于父元素定�?*/
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
  background: none;
}

header.scrolled {
  background: #fff;
}

.spacer {
  grid-column: 4; /* 定义其所在的列 */
  width: 50px;
}

@media (min-width: 768px) {
  .desktop {
    display: initial;
  }
}

header a {
  color: inherit;
}

header nav a {
  text-decoration: none;
  padding: 5px 10px;
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 3px;
}

/* 鼠标�?�?时的样式 */
header nav a:hover {
  box-shadow: rgba(50, 50, 93, 0.1) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.2) 0px 18px 36px -18px inset;
}

header nav.center {
  text-align: center;
  margin: 10px 0;
}

header nav.right {
  text-align: right;
  margin: 10px 0;
}

aside {
  position: fixed;
  /*设置元素当过渡效果，ease 轻松 悠闲的；慢速开始，然后变快，然后慢速结�?*/
  /*transition:过渡属�? 持续时间 效果函数 过渡延时;*/
  transition: right 0.4s ease;
  right: -240px;
  width: 240px;
  background-color: #fff;
  padding: 50px 40px;
  z-index: 4;
  height: 30vh;
}

aside a {
  display: block;
  color: inherit;
  /*文字特效(底线，删除线�?)参数*/
  text-decoration: none;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 500;
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 3px;
}

/*鼠标移动到a标�?�上的阴影效�?*/
aside a:hover {
  box-shadow: rgba(50, 50, 93, 0.1) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.2) 0px 18px 36px -18px inset;
}

aside.active {
  right: 0;
}

aside button.close {
  font-size: 24px;
  border: 0;
  /*背景透明*/
  background-color: transparent;
  /*Absolute，CSS�?的写法是：position:absolute; 绝�?�定位，参照浏�?�器的左上�?�，配合TOP、RIGHT、BOTTOM、LEFT(下面简称TRBL)进�?�定�?*/
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  /*文本的粗细程度，数值范围从100 �?900，�?�量�?100�? 正常粗细的值是400，�?700 的值�??认为�?粗体*/
  font-weight: 100;
  cursor: pointer;
}

.backdrop {
  display: none;
  position: fixed;
  /* inset 为简写属性，对应�? top、right、bottom �? left 属性。其�? margin 简写属性具有相同的多值�??�?*/
  inset: 0;
  background-color: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(5px);
  z-index: 4;
}

.backdrop.active {
  display: block;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>
