<template>
  <div class="footer">
    
    <router-link to="/">
      <img style="margin-left: 20px" src="../assets/img/logo_gosla_red.svg" >

      </router-link>
    <div class="content">
      
      <a href="">GOSLA © {{ currentYear }}</a>
      <a href="/contact">CONTACT</a>
      <a href="https://beian.miit.gov.cn/?site=brandsite&from=footer#/Integrated/index" target="_blank">蜀ICP备2023017650号-2</a>
      <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002005937" target="_blank">川公网安备51019002005937号</a>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "goslaFooter",

  computed:{
    currentYear(){
      var currentDate=new Date()
      return currentDate.getFullYear()
    }
  },
}
</script>

<style scoped>
div .footer {
  display: grid;
  grid-template-columns: auto 1fr auto;
  height: 10vh;
  width: 100%;
  text-align: center;
  background-color: #171a20;
}

div .content{
  margin: auto;
}

div a {
  text-decoration: none;
  padding: 5px 10px;
  text-align: center;
  font-size: 0.7rem;
  color: white;
}

div a:hover{
  text-decoration: underline;
}
</style>