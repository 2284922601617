<template>
  <!-- <section :style="{backgroundImage:'url('+homeImgSrc1+')'}">
      <goslaHeader/>
      
      <div class="section-p">
        <h1>科技拓开视野边界</h1>
      </div>
   
  
    </section> -->
  <div v-if="isMobile">
    <goslaHeader />

    <div style="width: 100%; margin: 0 auto;">
      <el-carousel :interval="5000" arrow="always" height="100vh">
        <el-carousel-item>
          <section :style="{ backgroundImage: 'url(' + homeImgSrc1 + ')' }">
            <div class="section-p-1">
              <h1>科技拓开视野边界</h1>
            </div>
          </section>
        </el-carousel-item>
        <!-- <el-carousel-item>
          <section :style="{ backgroundImage: 'url(' + homeImgSrc2 + ')' }">
            <div class="section-p-2">
              <h1>科技拓开视野边界</h1>
            </div>
          </section>
        </el-carousel-item> -->
      </el-carousel>
    </div>
    <goslaFooter />
  </div>

  <div v-else>
    <goslaHeader />

    <div style="width: 100%; margin: 0% auto">
      <el-carousel :interval="5000" arrow="always" height="100vh">
        <el-carousel-item>
          <section :style="{ backgroundImage: 'url(' + homeImgSrc1 + ')'}">
            <div class="section-p-1">
              <h1>科技拓开视野边界</h1>
            </div>
          </section>
        </el-carousel-item>
      </el-carousel>
    </div>
    <goslaFooter />
  </div>
</template>

<script>
//导入goslaHeader组件
import goslaHeader from "@/components/goslaHeader.vue";
import goslaFooter from "@/components/goslaFooter.vue";
import { ref } from "vue";
const isCollapse = ref(true);
// const handleOpen = (key: string, keyPath: string[]) => {
//   console.log(key, keyPath)
// }
// const handleClose = (key: string, keyPath: string[]) => {
//   console.log(key, keyPath)
// }
export default {
  name: "HomeView",
  data() {
    return {
      homeImgSrc1: require("@/assets/img/homepage-UAV4.png"),
      homeImgSrc2: require("@/assets/img/med.webp"),
      isMobile: ref(window.innerWidth < 768),
      // handleOpen,
      // handleClose,
    };
  },
  components: { goslaFooter, goslaHeader },
};
</script>

<style scoped>
section {
  height: 100vh;
  background-size: cover;
  background-position: center center;
}

.section-p-1 {
  /*opacity 属性指定了一个元素的不透明度,0-1*/
  opacity: 1;
  padding-top: 55vh;
  text-align: center;
}
.section-p-2 {
  /*opacity 属性指定了一个元素的不透明度,0-1*/
  opacity: 1;
  width: 100%;
  height: 100vh;
  padding-top: 8vh;
  padding-bottom: 10vh;
  text-align: center;
  font-size: 50px;
}

@media (max-width: 768px) {
  .section-p-1{
      padding-top: 60vh;
  }
}

@media (min-width: 769px) and (max-width: 1920px) {
  .section-p {
    padding-top: 80vh;
  }
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
