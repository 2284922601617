import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import axios from "axios";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/phase/desc',
        name: 'phaseDesc',
        component: () => import(/* webpackChunkName: "about" */ '../views/PhaseDescView.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
    },
    {
        path: '/research',
        name: 'research',
        component: () => import(/* webpackChunkName: "about" */ '../views/ResearchView.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "about" */ '../views/RegisterView.vue')
    },
    {
        path: '/phase/draw',
        name: 'phaseDraw',
        component: () => import(/* webpackChunkName: "about" */ '../views/PhaseDrawView.vue'),
        meta: { requiresButton: false }  // 添加一个 meta 字段表示需要通过按钮跳转
    },
    {
        path: '/error',
        name: 'error',
        component: () => import(/* webpackChunkName: "about" */ '../views/ErrorView.vue')
    },
    {
        path:'/forgetPwd',
        name:'forgetPwd',
        component: () => import(/* webpackChunkName: "about" */ '../views/ForgetPwd.vue')
    },
    {
        path:'/contact',
        name:'contact',
        component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
    },
    {
        path:'/product',
        name:'product',
        component: () => import(/* webpackChunkName: "about" */ '../views/ProductView.vue')
    },
    {
        path: '/UAVHistory',
        name: 'UAVHistoryView',
        component: () => import(/* webpackChunkName: "about" */ '../views/UAVHistoryView.vue'),
    },
    {
        path: '/corelessUAVDes1',
        name: 'OpenSourceCorelessUAV.vue',
        component: () => import(/* webpackChunkName: "about" */ '../views/OpenSourceCorelessUAV.vue')
    }
    // {
    //     path: '/mzsProcessor/desc',
    //     name: 'mzsPDescView',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/mzsPDescView.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // 不是通过按钮跳转路径
    if(to.path==='/phase/draw' && Object.keys(to.query).length===0){
        next('/login')
    }else {
        // 拿到/phase/desc按钮跳转过来携带的参数
        to.meta.requiresButton=to.query.requiresButton
    }
    if (to.path === '/phase/draw' && from.path === '/phase/desc' && to.meta.requiresButton) {
        axios({
            method: 'post',
            url: '/api/phase/allow',
            headers: {                            // 请求头
                "Content-Type": "application/json;charset=utf-8",
                "userEmail": sessionStorage.getItem('userEmail')
            },
        }).then(res=>{
            next()
        }).catch(error=>{
            next({path:'/error',query:{status:error.response.status,message:error.response.data}})
        })
    }else if (to.path === '/phase/draw' && !to.meta.requiresButton) { // 不是通过按钮跳转
        next('/login');
    } else {
        next(); // 其他情况继续导航
    }
});

export default router
